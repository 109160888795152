import React from 'react'
import Layout from '../../layouts/Layout'
import BasicHero from '../../components/Hero/BasicHero'

import { Container } from 'theme-ui'

export default function news() {
  return (
    <Layout theme={'dark'}>
      <BasicHero heading="News" />
      <Container
        sx={{
          flexDirection: 'column',
        }}
      >
        <h2
          sx={{
            textAlign: 'left',
            mb: [3, null, null, 0],
          }}
        >
          Curo Emerges from Chapter 11 Better Positioned for Long - Term
          Profitable Growth{' '}
        </h2>{' '}
        <p>
          <b> GREENVILLE, S.C., July 19, 2024 </b> – CURO Group Holdings Corp.
          today announced that it has emerged from Chapter 11 protection as Curo
          Group Holdings LLC(“Curo” or the“ Company”), having taken all required
          actions and satisfied all remaining conditions to effectiveness of its
          Joint Prepackaged Plan of Reorganization(the“ Plan”), which was
          confirmed by the U.S.Bankruptcy Court for the Southern District of
          Texas, Houston Division on May 16, 2024.{' '}
        </p>{' '}
        <p>
          {' '}
          “Today’ s announcement marks the beginning of a new chapter for Curo,
          ”said Doug Clark, Chief Executive Officer at Curo.“We are emerging as
          a substantially stronger business and are now better positioned to
          achieve long - term profitable growth.We are incredibly appreciative
          of the support from all of our stakeholders as we continue to provide
          our customers with a variety of convenient, easily accessible
          financial services.”{' '}
        </p>{' '}
        <p>
          The Plan relieves Curo of approximately $1 billion in debt and not
          less than $75 million of annual interest obligations and will
          otherwise provide for improved liquidity.The Plan provides for payment
          in full of the allowed claims of general unsecured creditors, which
          includes, among others, trade, customer, employee, and landlord
          claims.{' '}
        </p>{' '}
        <p>
          As previously announced, Curo’ s Plan received overwhelming support
          from existing stakeholders of CURO Group Holdings Corp.constituting(1)
          100 % of the Prepetition 1 L Term Loan Claims and Prepetition 1.5 L
          Notes Claims that voted on the Plan, (2) more than 99.9 % of the
          Prepetition 2 L Notes Claims that voted on the Plan, and(3) more than
          95 % of existing equity interests that voted on the Plan.The Company
          also obtained recognition of the Plan from the Ontario Superior Court
          of Justice(Commercial List)(the“ Canadian Court”).{' '}
        </p>{' '}
        <p>
          Emerging as a private company, the equity of Curo will not be listed
          on any public stock exchange.{' '}
        </p>{' '}
        <p>
          Akin Gump Strauss Hauer & Feld LLP served as legal counsel to the
          Company, Cassels Brock & Blackwell LLP served as Canadian legal
          counsel to the Company, and Oppenheimer & Co.Inc., served as
          investment banker to the Company.Wachtell, Lipton, Rosen & Katz and
          Vinson & Elkins LLP served as legal counsel to the ad hoc group of
          Curo’ s secured lenders(the“ Ad Hoc Group”), and Houlihan Lokey
          Capital, Inc.served as financial advisor to the Ad Hoc Group.{' '}
        </p>{' '}
        <p>
          <b> About Curo </b>{' '}
        </p>{' '}
        <p>
          Curo is a leading consumer credit lender serving U.S.and Canadian
          customers for over 25 years.Our roots in the consumer finance market
          run deep.We’ ve worked diligently to provide customers a variety of
          convenient, easily accessible financial services.Our decades of
          diversified data power a hard - to - replicate underwriting and
          scoring engine, mitigating risk across the full spectrum of credit
          products.We operate under a number of brands including Cash Money®,
          LendDirect®, Heights Finance, Southern Finance, Covington Credit,
          Quick Credit and First Heritage Credit.{' '}
        </p>{' '}
        <p>
          <b> Forward - Looking Statements </b>{' '}
        </p>{' '}
        <p>
          This press release contains forward - looking statements.In addition,
          words such as“ estimate, ”“believe, ”“forecast, ”“step, ”“plan,
          ”“predict, ”“focused, ”“project, ”“is likely, ”“guidance, ”“expect,
          ”“anticipate, ”“intend, ”“should, ”“will, ”“confident, ”variations of
          such words and similar expressions are intended to identify forward -
          looking statements.{' '}
        </p>{' '}
        <p>
          The Company’ s ability to achieve these forward - looking statements
          is based on certain assumptions, judgments and other factors, both
          within and outside of the Company’ s control, that could cause actual
          results to differ materially from those in the forward - looking
          statements, including, but not limited to, the effects on the Company’
          s business and clients of general economic and financial market
          conditions, as well as the following: a liquid trading market for the
          Company’ s common units or warrants may not develop; provisions in the
          Company’ s organizational documents could delay or prevent a change in
          control, or cause the price of the Company’ s common units to decline;
          the Company’ s common units will be subject to dilution by the
          Company’ s management incentive plan and warrants; existing cash
          balances and funds generated from operations may not be sufficient to
          finance the Company’ s operations and meet its cash requirements; the
          Company could be subject to cyber - attacks and other security
          breaches; the Company may be unable to retain or hire key employees;
          the Company may be affected by tax assessments, unfavorable tax audit
          outcomes, delayed tax filings and future increased levels of taxation;
          and additional risks and uncertainties, all of which may have
          potential adverse effects on the Company’ s business and relationships
          with customers, vendors, employees, service providers and
          suppliers.Additionally, the Company’ s financial results following the
          effective date(the“ Effective Date”) of the Company’ s plan of
          reorganization(the“ Plan”) will not be comparable to the Company’ s
          available historical financial information for periods prior to the
          Effective Date as a result of the implementation of the Plan and the
          transactions contemplated thereby and its adoption of fresh start
          accounting.{' '}
        </p>{' '}
        <p>
          There may be additional risks that the Company does not presently know
          or currently believes are immaterial that could also cause actual
          results to differ from those contained in the forward - looking
          statements.Should one or more of these risks or uncertainties
          materialize, or should any of the Company 's assumptions prove
          incorrect, the Company 's actual financial condition, results of
          operations, future performance and business may vary in material
          respects from the performance projected in these forward - looking
          statements.Any forward - looking statement in this press release is
          based only on information available to the Company at the time such
          statement was made and speaks only as of the date on which it was
          made.The statements included in this press release are not guarantees
          of performance or results.Given these risks and uncertainties,
          investors should not place undue reliance on forward - looking
          statements as a prediction of actual future results.The Company
          undertakes no obligation to update, amend or clarify any forward -
          looking statement for any reason.No representation or warranty is
          given as to the achievability or reasonableness of any projections,
          estimates or forward - looking statements.This press release is not
          intended to provide, and should not be relied upon for, accounting,
          legal or tax advice or any investment recommendation.{' '}
        </p>{' '}
        <p>
          Investors:
          <br />
          Email:{' '}
          <a href="mailto:ir@curo.com" target="_blank">
            IR @curo.com{' '}
          </a>{' '}
        </p>{' '}
        <p>
          Media:
          <br />
          Jude Gorman / Dan Moore, Collected Strategies <br />
          Email:{' '}
          <a href="mailto:CURO-CS@collectedstrategies.com" target="_blank">
            CURO - CS @collectedstrategies.com{' '}
          </a>{' '}
        </p>{' '}
      </Container>{' '}
    </Layout>
  )
}

// export const Head = ({ data }) => {
//   const page = data.contentfulPageJson
//   return <Seo title={page.pageTitle} />
// }
